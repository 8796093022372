<template>
  <!-- NAME[epic=采购] 采购退货单 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="90px"
    >
      <el-form-item prop="operator_id">
        <el-select
          v-model="form.operator_id"
          clearable
          placeholder="请选择经办人"
          style="width: 180px"
        >
          <el-option
            v-for="(item, index) in jbrList"
            :key="index"
            :label="item.user_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="supp_id">
        <el-select
          v-model="form.supp_id"
          clearable
          filterable
          placeholder="请选择供应商"
          style="width: 180px"
        >
          <el-option
            v-for="(item, index) in gysList"
            :key="index"
            :label="item.supp_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="bill_code">
        <el-input
          v-model="form.bill_code"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item prop="status">
        <el-select
          v-model="form.status"
          clearable
          placeholder="状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in status"
            :key="index"
            :label="item.text"
            :value="item.status"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item prop="pay_status">
        <el-select
          v-model="form.pay_status"
          clearable
          placeholder="结算状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in pay_status"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item prop="print_status">
        <el-select
          v-model="form.print_status"
          clearable
          placeholder="打印状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in print_status"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 220px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="handleReset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleAdd">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width="auto"
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleCheck(0, row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="120px"
      >
        <template #default="{ $index, row }">
          <div v-if="row.status == 1">
            <div v-if="row.pay_status_text == '未结清'">
              <el-button
                type="text"
                @click.native.prevent="handleCheck($index, row)"
              >
                查看
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleRukuan($index, row)"
              >
                入款
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDelete($index, row)"
              >
                作废
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handlePrint($index, row)"
              >
                打印
              </el-button>
            </div>
            <div v-if="row.pay_status_text == '已结清'">
              <el-button
                type="text"
                @click.native.prevent="handleCheck($index, row)"
              >
                查看
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDelete($index, row)"
              >
                作废
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handlePrint($index, row)"
              >
                打印
              </el-button>
            </div>
          </div>
          <div v-else>
            <el-button
              type="text"
              @click.native.prevent="handleCheck($index, row)"
            >
              查看
            </el-button>
            <el-button
              v-if="row.can_check === 1 && row.status != 2"
              type="text"
              @click.native.prevent="handleReview($index, row)"
            >
              审核
            </el-button>
            <el-button
              v-if="
                row.can_scrap === 1 &&
                row.status != 2 &&
                authbutton[3].is_check == 1
              "
              type="text"
              @click.native.prevent="handleDelete($index, row)"
            >
              作废
            </el-button>
            <el-button
              v-if="row.status != 2"
              type="text"
              @click.native.prevent="handlePrint($index, row)"
            >
              打印
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add ref="add" @refresh="fetchData"></add>
    <review ref="review" @refresh="fetchData"></review>
    <check ref="check"></check>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="CT"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <rukuan ref="rukuan" @refresh="fetchData"></rukuan>
  </div>
</template>
<script>
  import _ from 'lodash'
  import Rukuan from './components/rukuan.vue'
  import {
    jbrSelect,
    gysSelect,
    purchaseReturnList,
    statusSelect,
    purchaseDelete3,
  } from '@/api/purchase'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import Add from './components/add'
  import Review from './components/review'
  import Check from './components/check'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { getSelect } from '@/api/saleOrder'
  export default {
    name: 'PurchaseReturn',
    components: {
      GoodsSearch,
      Add,
      Review,
      Check,
      RuilangDisplayDialog,
      Rukuan,
    },
    data() {
      return {
        showDialog: false,
        loading: false,
        time: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        jbrList: [],
        fkrList: [],
        gysList: [],
        ckList: [],
        fkzhList: [],
        data_id: 0,
        pay_status: [
          {
            id: 0,
            value: '未结清',
          },
          {
            id: 1,
            value: '已结清',
          },
        ],
        print_status: [
          {
            id: 0,
            value: '未打印',
          },
          {
            id: 1,
            value: '已打印',
          },
        ],
        // 状态
        status: [],
        form: {
          supp_id: '', //供应商id
          operator_id: '', //经办人id
          depot_id: '', //仓库id
          remark: '', //备注

          bill_code: '', //单号
          goods_id: '', //商品id
          pay_status: '', //结算状态 0未结清 1已结清
          status: '', // 状态 0待审核 1已审核 2已作废 3已冲改
          print_status: '', //打印状态  0未打印  1已打印
          start_time: '', //开始时间
          end_time: '', //结束时间
          is_closed: '', //是否 显示冲改作废单据  1是，0不是
          pageNo: 1, //当前页，默认1
          pageSize: 10, //每页条数，默认10
          sort: '', //排序字段，默认id
          order: '', //排序类型，默认asc
        },
        // 表格
        list: [],
        checkList: [
          '单号',
          '供应商',
          '经办人',
          '退货金额',
          '待入款',
          '退货仓库',
          '状态',
          '下单时间',
          '备注',
          '打印',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '供应商',
            prop: 'supp_name',
            width: '',
          },
          {
            order: 3,
            label: '经办人',
            prop: 'operator',
            width: '',
          },
          {
            order: 4,
            label: '退货金额',
            prop: 'total_amount',
            width: '',
          },
          {
            order: 4.5,
            label: '待入款',
            prop: 'arrears',
            width: '',
          },
          {
            order: 5,
            label: '退货仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 6,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
          {
            order: 7,
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 8,
            label: '备注',
            prop: 'remark',
            width: '',
          },
          {
            order: 9,
            label: '打印',
            prop: 'print_count',
            width: '',
          },
        ],
        authbutton: [],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      // 经办人&付款人下拉
      jbrSelect().then((res) => {
        this.jbrList = res.data.rows
        this.fkrList = res.data.rows
      })
      // 供应商下拉
      gysSelect().then((res) => {
        this.gysList = res.data.rows
      })
      // 状态下拉
      statusSelect().then((res) => {
        this.status = res.data
      })
      getSelect().then((res) => {
        // this.areaList = res.data.cust_area //区域
        this.authbutton = res.data.fun_list
      })
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        let { data, msg, code } = await purchaseReturnList(this.form)
        console.log(data)
        this.list = data.rows
        this.total = Number(data.total)
      },
      selectGoods(val) {
        console.log('选中的val', val)
        if (val) {
          this.form.goods_id = val.goods_id
        }
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [5]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return Number(total) + Number(num)
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      // 表单按钮
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleReset() {
        this.clearForm()
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.time = []
        this.$refs.goodsSearch.resetForm()
        this.form.goods_id = ''
      },
      // 分页
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 表格操作、
      handleCheck(index, row) {
        this.$refs.check.id = row.id
        this.$refs.check.showDialog = true
      },
      handleReview(index, row) {
        this.$refs.review.id = row.id
        this.$refs.review.showDialog = true
      },
      handleDelete(index, row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            purchaseDelete3({ id: row.id }).then((r) => {
              this.$message.success('作废成功')
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handlePrint(index, row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      // 入款
      handleRukuan(index, row) {
        this.$refs.rukuan.order_id = row.id
        this.$refs.rukuan.tuihuo = row.total_amount
        this.$refs.rukuan.yirukuan = row.real_amount
        this.$refs.rukuan.dairukuan = row.arrears
        this.$refs.rukuan.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
