var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "添加采购退货单",
        top: "5vh",
        fullscreen: "",
        visible: _vm.showDialog,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商:", prop: "supp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "请选择供应商" },
                      on: { change: _vm.gysChange },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.gysList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人:", prop: "operator_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "请选择经办人" },
                      model: {
                        value: _vm.form.operator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operator_id", $$v)
                        },
                        expression: "form.operator_id",
                      },
                    },
                    _vm._l(_vm.jbrList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "出库仓库:", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "出库仓库" },
                      on: { change: _vm.depotChange },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { placeholder: "使用扫码器添加商品" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "minUnit" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.minUnit,
                        callback: function ($$v) {
                          _vm.minUnit = $$v
                        },
                        expression: "minUnit",
                      },
                    },
                    [_vm._v("按小单位采购")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "u-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tableSort",
                  attrs: {
                    data: _vm.list,
                    "use-virtual": "",
                    "show-overflow-tooltip": false,
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                    height: _vm.height,
                    "row-height": _vm.rowHeight,
                    border: false,
                    "data-changes-scroll-top": false,
                  },
                },
                [
                  _c(
                    "u-table-column",
                    {
                      attrs: { align: "center", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            return [_vm._v(" " + _vm._s($index + 1) + " ")]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.checkList,
                                    callback: function ($$v) {
                                      _vm.checkList = $$v
                                    },
                                    expression: "checkList",
                                  },
                                },
                                _vm._l(_vm.columns, function (item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item.label },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("u-table-column", {
                      key: tableIndex,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.label == "商品名称" ? "240" : "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          item.prop == "goods_type"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: row.goods_type,
                                          callback: function ($$v) {
                                            _vm.$set(row, "goods_type", $$v)
                                          },
                                          expression: "row.goods_type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.typeSelect,
                                        function (typeItem, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: typeItem.value,
                                              value: typeItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : item.prop == "goods_name"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c("goods-search", {
                                      ref: "goodsSearch",
                                      refInFor: true,
                                      attrs: {
                                        "f-key": row.goods_name,
                                        "brand-id": _vm.form.brand_id,
                                        "depot-id": _vm.form.depot_id,
                                        "is-brand":
                                          _vm.form.supp_id == "" ? true : false,
                                      },
                                      on: {
                                        "add-rows": function ($event) {
                                          return _vm.addRows($event, $index)
                                        },
                                        "select-goods-all": function ($event) {
                                          return _vm.selectGoods($event, row)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "unit_name"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectUnit($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.unit_id,
                                          callback: function ($$v) {
                                            _vm.$set(row, "unit_id", $$v)
                                          },
                                          expression: "row.unit_id",
                                        },
                                      },
                                      _vm._l(
                                        row.arr_unit,
                                        function (unitItem, unitIndex) {
                                          return _c("el-option", {
                                            key: unitIndex,
                                            attrs: {
                                              label: unitItem.unit_name,
                                              value: unitItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : item.prop == "goods_production_date"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "180px" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        format: "yyyy 年 MM 月 dd 日",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: row.goods_production_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row,
                                            "goods_production_date",
                                            $$v
                                          )
                                        },
                                        expression: "row.goods_production_date",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "goods_price"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.priceSum($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.goods_price,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_price", $$v)
                                        },
                                        expression: "row.goods_price",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "goods_num"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.priceSum($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.goods_num,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_num", $$v)
                                        },
                                        expression: "row.goods_num",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "sum_money"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.moneyChange($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.sum_money,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sum_money", $$v)
                                        },
                                        expression: "row.sum_money",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "remark"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: row.remark,
                                        callback: function ($$v) {
                                          _vm.$set(row, "remark", $$v)
                                        },
                                        expression: "row.remark",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("u-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "pay", staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "退货金额:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.allSum,
                              callback: function ($$v) {
                                _vm.allSum = $$v
                              },
                              expression: "allSum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "入款:", prop: "" } },
                        [
                          _c(
                            "u-table",
                            { attrs: { data: _vm.list2 } },
                            [
                              _c("u-table-column", {
                                attrs: {
                                  label: "付款账户",
                                  prop: "account",
                                  width: "180",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: { placeholder: "付款账户" },
                                            model: {
                                              value: row.account_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "account_id", $$v)
                                              },
                                              expression: "row.account_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.fkzhList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  value: item.id,
                                                  label: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("u-table-column", {
                                attrs: {
                                  label: "付款金额",
                                  prop: "amount",
                                  width: "180",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "付款金额",
                                            type: "number",
                                          },
                                          model: {
                                            value: row.amount,
                                            callback: function ($$v) {
                                              _vm.$set(row, "amount", $$v)
                                            },
                                            expression: "row.amount",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("u-table-column", {
                                attrs: {
                                  label: "经办人",
                                  prop: "create_name",
                                  width: "180",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: {
                                              placeholder: "选择经办人",
                                            },
                                            model: {
                                              value: row.user_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "user_id", $$v)
                                              },
                                              expression: "row.user_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.fkrList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.user_name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("u-table-column", {
                                attrs: {
                                  label: "操作",
                                  prop: "create_name",
                                  width: "160",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdd($index)
                                              },
                                            },
                                          },
                                          [_vm._v(" 添加 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete($index)
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.save(0)
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c("el-button", [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }