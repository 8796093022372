<template>
  <el-dialog
    title="采购退货入款"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :close-on-click-modal="false"
    center
    :modal="false"
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="120px"
        label-suffix=":"
      >
        <el-form-item label="退货金额" prop="">{{ tuihuo }}</el-form-item>
        <el-form-item label="已入款" prop="">{{ yirukuan }}</el-form-item>
        <el-form-item label="待入款" prop="">{{ dairukuan }}</el-form-item>
        <div style="display: flex; justify-content: space-around">
          <el-input
            v-model="form.pay_amount"
            placeholder="请输入入款金额"
            style="width: 140px"
          ></el-input>
          <el-select
            v-model="form.account_id"
            clearable
            style="width: 140px"
            placeholder="请选择入款账户"
          >
            <el-option
              v-for="(i, idx) in fkzhList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </div>
      </el-form>
      <div class="dialog-footer" style="margin-top: 15px">
        <el-button type="primary" @click="handleSub">确定</el-button>
        <el-button @click="showDialog = false">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { fkzhSelect } from '@/api/purchase'
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        loading: false,
        form: {
          pay_amount: '',
          account_id: '',
        },
        order_id: '',
        tuihuo: '',
        yirukuan: '',
        dairukuan: '',
        fkzhList: [],
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (!v) {
          this.form = {
            pay_amount: '',
            account_id: '',
          }
        }
      },
    },
    created() {},
    mounted() {
      // 付款账户
      fkzhSelect().then((res) => {
        this.fkzhList = res.data
      })
    },
    methods: {
      async handleSub() {
        if (this.form.pay_amount && this.form.account_id) {
          this.loading = true
          let res = await postAction('/purchaseAdmin/return/return-pay', {
            order_id: this.order_id,
            pay_amount: this.form.pay_amount,
            account_id: this.form.account_id,
          })
          this.$message.success('操作成功')
          this.loading = false
          this.showDialog = false
          this.$emit('refresh')
        } else {
          this.$message.error('请输入数据')
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
